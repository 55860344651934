/**
Author: Tyshan Prince (tyshantprince@gmail.com)
Date: July 09, 2019


Description:

Landing Page for Triage tool

*/


<template>
  <q-page>
    <!-- Contain header text -->
    <div>
      <div class="row topSide">
        <div class="col-12">
          <q-btn
            class="text-right"
            color="secondary"
            style="float: right"
            flat
            icon-right="close"
            label="exit"
            @click="exit"
          ></q-btn>
        </div>
        <div class="flex flex-center" id="intro-position" style="">
          <p
            class="text-center text-bold foundationFont"
            style="color: #00667e"
          >
            Take the legal check up to explore your rights and get a report
            related to your situation.
          </p>
          <div id="borderBetween"></div>
          <p class="text-center foundationFont q-pt-xl" style="color: #00667e">
            Realice el control legal para explorar sus derechos y obtener un
            informe relacionado con su situacion.
          </p>
        </div>
      </div>
      <!-- Contain intro buttons -->
      <div class="row bottomSide">
        <span class="row justify-center q-pa-md startTriage" id="intro-button">
          <q-btn
            @click="start"
            class="q-ma-sm triageFont beginBtn"
            size="2.5vh"
            no-caps
            color="primary"
          >
            <div style="color: #ffffff">ENGLISH</div>
          </q-btn>
          <q-btn
            @click="start_spanish"
            class="q-ma-sm triageFont beginBtn"
            size="2.5vh"
            no-caps
            color="primary"
          >
            <div style="color: #ffffff">ESPAÑOL</div>
          </q-btn>
        </span>
      </div>
    </div>
  </q-page>
</template>


<!-- Global site tag (gtag.js) - Google Analytics -->
<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=UA-9894633-23"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-9894633-23');
</script> -->

<style>
.triageFont {
  font-family: "Open Sans", sans-serif;
}

.foundationFont {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
  font-size: 3.5vmin;
}

.topSide {
  background-color: #fff;
}

.bottomSide {
  background-color: #82b8c4;
  background: border-box;
}

.startTriage {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#borderBetween {
  width: 100%;
  margin: auto;
  border-top: 1px solid #0c667d;
}
/* .beginBtn {
  border: 2px solid black;
  border-radius: 20px;
} */

.beginBtn:hover {
  background-color: #468b9f !important;
}

.beginBtn {
  color: #ffffff;
  height: 100%;
  border-radius: 20px;
}

#intro-position {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#intro-button {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 2560px) {
  .foundationFont {
    font-size: 1.25vmax;
  }
  .foundationSubFont {
    font-size: 0.75vmax;
  }
}

@media screen and (max-width: 767px) {
  .topSide {
    background-color: #fff;
    height: 50vh;
  }

.bottomSide{
  background-color: #82b8c4;
  height: 50vh;
  }
}

@media screen and (max-width: 576px) {
  #intro-button {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
}

</style>

<script>

//import ua from 'universal-analytics'

//var visitor = ua('UA-9894633-23');

export default {
  name: "PageIndex",
  mounted() {
    //visitor.pageview("/", "https://webapp.azcrimevictimhelp.org/", "Home").send()

    this.$store.dispatch('resetState');

    let fontload = document.createElement("link");
    fontload.setAttribute(
      "href",
      "//fonts.googleapis.com/css?family=Open+Sans"
    );
    document.head.appendChild(fontload);
  },
  methods: {
    start() {
      this.$store.dispatch("storeLanguage", "EN");
      this.$router.push("triage");
    },
    start_spanish() {
      this.$store.dispatch("storeLanguage", "ES");
      this.$router.push("triage_spanish");
    },
    exit() {
      window.location = "http://www.google.com";
    },
  },
};
</script>
