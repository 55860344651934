var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-page", [
    _c("div", [
      _c("div", { staticClass: "row topSide" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("q-btn", {
              staticClass: "text-right",
              staticStyle: { float: "right" },
              attrs: {
                color: "secondary",
                flat: "",
                "icon-right": "close",
                label: "exit",
              },
              on: { click: _vm.exit },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex flex-center", attrs: { id: "intro-position" } },
          [
            _c(
              "p",
              {
                staticClass: "text-center text-bold foundationFont",
                staticStyle: { color: "#00667e" },
              },
              [
                _vm._v(
                  "\n          Take the legal check up to explore your rights and get a report\n          related to your situation.\n        "
                ),
              ]
            ),
            _c("div", { attrs: { id: "borderBetween" } }),
            _c(
              "p",
              {
                staticClass: "text-center foundationFont q-pt-xl",
                staticStyle: { color: "#00667e" },
              },
              [
                _vm._v(
                  "\n          Realice el control legal para explorar sus derechos y obtener un\n          informe relacionado con su situacion.\n        "
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "row bottomSide" }, [
        _c(
          "span",
          {
            staticClass: "row justify-center q-pa-md startTriage",
            attrs: { id: "intro-button" },
          },
          [
            _c(
              "q-btn",
              {
                staticClass: "q-ma-sm triageFont beginBtn",
                attrs: { size: "2.5vh", "no-caps": "", color: "primary" },
                on: { click: _vm.start },
              },
              [
                _c("div", { staticStyle: { color: "#ffffff" } }, [
                  _vm._v("ENGLISH"),
                ]),
              ]
            ),
            _c(
              "q-btn",
              {
                staticClass: "q-ma-sm triageFont beginBtn",
                attrs: { size: "2.5vh", "no-caps": "", color: "primary" },
                on: { click: _vm.start_spanish },
              },
              [
                _c("div", { staticStyle: { color: "#ffffff" } }, [
                  _vm._v("ESPAÑOL"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }